<template>
  <div class="home-container">
    <DoodleGeneralForm in-progress-message="Submitting the assignment, Please wait..."
                       form="" no-box="true"
                       :inProgress="doodleForm.inProgress" :completed="doodleForm.completed"
                       :errorMessageText="doodleForm.errorMessage"
                       success-message="Assignment Submitted Successfully."
                       :hide-action-close="true">
    <div class="columns is-marginless is-paddingless">
      <div class="column is-8-tablet is-offset-2-tablet is-paddingless">
        <div v-for="(submission, index) in submissions" v-bind:key="index">
          <div class="question-list">{{index+1}}.&nbsp;&nbsp;
            <a @click="$emit('loadQuestion', index)">
              <span v-if="submission.isMarkedForReview">**</span>
              <span v-show="isAnswered(submission.answer)" class="has-text-success">Answered</span>
              <span v-show="!isAnswered(submission.answer)" class="has-text-danger">Not Answered</span>
            </a>
          </div>
        </div>
        <div class="marked-for-review">** - Marked For Review</div>
      </div>
    </div>
    <div v-if="isAttend && (isStudentStatusInProgress || isStudentStatusYetToStart)" class="has-text-centered buttons-div">
      <button class="button is-info has-text-weight-bold" @click="showSubmitAssignment=true" v-if="!showSubmitAssignment">Submit Assignment</button>
      <ConfirmationBox @action="submitAssignment" confirmation-text="Do you want to submit the Assignment?" yes-text="Yes"
                       no-text="No" @close="showSubmitAssignment=false" :show-confirmation="showSubmitAssignment" />
    </div>
    <div v-if="isAttend && !isStudentStatusInProgress" class="has-text-centered buttons-div">
      <button class="button is-info has-text-weight-bold" @click="gotoSummary">Goto Summary</button>
    </div>
    </DoodleGeneralForm>
    <div v-if="isAttend && !doodleForm.completed && isStudentStatusInProgress" class="has-text-centered">
      <button class="button has-text-weight-bold" @click="saveAndExit">Save And Exit</button>
    </div>
    <div v-if="isAttend && (doodleForm.completed || !isStudentStatusInProgress)" class="has-text-centered">
      <button class="button has-text-weight-bold" @click="gotoInsHome">Close</button>
    </div>
  </div>
</template>

<script>
import formMixin from '../../../assets/javascript/form-mixin'
import DoodleGeneralForm from '../../Utils/DoodleGeneralForm'
import { eventBus } from '../../../assets/javascript/event-bus'
import { GURU_CONSTANTS, GURU_EVENTS } from '../../../assets/javascript/constants'
import ConfirmationBox from '../../Utils/ConfirmationBox'

export default {
  name: 'guruAssignmentSummary',
  components: { ConfirmationBox, DoodleGeneralForm },
  mixins: [formMixin],
  props: ['currentAssociation', 'assignment', 'submissions', 'isPreview', 'isAttend', 'isEvaluate', 'studentUnderEvaluation'],
  data () {
    return {
      showSubmitAssignment: false
    }
  },
  mounted () {
    eventBus.$on(GURU_EVENTS.SUBMIT_ASSIGNMENT, () => {
      this.submitAssignment()
    })
  },
  beforeDestroy () {
    eventBus.$off(GURU_EVENTS.SUBMIT_ASSIGNMENT)
  },
  methods: {
    isAnswered (answer) {
      return _.isBoolean(answer) || _.isNumber(answer) || !_.isEmpty(answer)
    },
    saveAndExit () {
      this.$router.push('/guru/ins/' + this.$route.params.instituteCode)
    },
    gotoSummary () {
      this.$emit('loadQuestionSummaryPage')
    },
    submitAssignment () {
      this.doodleForm.errorMessage = ''
      this.executeAPIWitoutValiation({
        url: '/api/guru-api/assignment/submitAssignment',
        data: {
          instituteCode: this.currentAssociation.institute.code,
          assignmentId: this.assignment.insAssignmentId
        },
        method: 'post',
        markCompleted: true,
        form: this.doodleForm,
        jdaCategory: window.jda.CATEGORY.GURU,
        jdaEvent: 'guru-submit-assignment',
        jdaLabel: ''
      })
    },
    gotoInsHome () {
      this.$router.push('/guru/ins/' + this.$route.params.instituteCode)
    }
  },
  computed: {
    isStudentStatusInProgress () {
      return this.assignment.submissionStatus === GURU_CONSTANTS.ASSIGNMENT_STUDENT_STATUS.IN_PROGRESS
    },
    isStudentStatusYetToStart () {
      return this.assignment.submissionStatus === GURU_CONSTANTS.ASSIGNMENT_STUDENT_STATUS.YET_TO_START
    }
  }
}
</script>

<style scoped lang="scss">
    .question-list {
      font-size: 0.8em;
      font-weight: bold;
    }

   .marked-for-review {
     margin-top: 20px;
     font-size: 0.8em;
     font-weight: bold;
   }

  .buttons-div {
    margin:1em;
  }
</style>
